/* global Common,CommonApi,$,Const,AmazonCognitoIdentity */
/**
 * @file ログイン
 * @author Ozaki Takao
 */
(function () {
  'use select';
  if (typeof window.Login === 'undefined') {
    window.Login = {};
  }
  /**
   * コンストラクタ
   *
   * @constructor
   * @param {string} id コンテンツ挿入箇所のid
   */
  window.Login = function (id) {
    /**
     * コンテンツ挿入箇所のid
     * @type {string}
     */
    this.id = id;
  };
  window.Login.prototype = {
    /**
     * 初期処理
     * @access public
     */
    init: function () {
      var that = this;
      // エラーメッセージ
      that.$MessageParagraph = $('#login-msg-error');
      that.$MessageParagraph.showMessage = function (msgStr, replaceStrs) {
        var str = msgStr;
        if (Array.isArray(replaceStrs)) {
          str = Common.stringFormat(msgStr, replaceStrs);
        }
        that.$MessageParagraph.text(str);
        that.$MessageParagraph.removeClass('s-hide');
      };
      that.$MessageParagraph.hideMessage = function () {
        that.$MessageParagraph.text('');
        that.$MessageParagraph.addClass('s-hide');
      };
      that.$MessageParagraph.hideMessage();

      // フォーム
      $('form').on('submit', function (event) {
        event.originalEvent.preventDefault();

        // 認証情報の取得
        var authenticationData = that.getAuthenticationData_();
        if (authenticationData === null) {
          return;
        }

        // ローディング表示
        Common.showLoading();

        // ユーザー認証
        that.authenticateUser_(authenticationData);
      });
    },
    /**
     * 認証情報の取得
     * @access private
     * @returns {object} 認証情報
     */
    getAuthenticationData_: function () {
      var that = this;
      var userNameElementId = 'form-item-user-id';
      var passwordElementId = 'form-item-pw';
      if (document.getElementById(userNameElementId) === null ||
          document.getElementById(passwordElementId) === null) {
        return null;
      }
      var idStr = document.getElementById(userNameElementId).value;
      var passwdStr = document.getElementById(passwordElementId).value;
      var authenticationData = that.verifyUserInfo_(idStr, passwdStr);
      if (authenticationData['Error'] === 'E01') {
        that.$MessageParagraph.showMessage(Common.MSG.COMMON_ERROR_001, ['ユーザーID']);
        return null;
      }
      if (authenticationData['Error'] === 'E02') {
        that.$MessageParagraph.showMessage(Common.MSG.COMMON_ERROR_001, ['パスワード']);
        return null;
      }
      return authenticationData['Auth'];
    },
    /**
     * 入力ログイン情報のチェック
     * @access private
     * @param {string} id ID
     * @param {string} password パスワード
     * @returns {object} 認証情報
     */
    verifyUserInfo_: function (id, password) {
      var errorCode = '';
      if (id === null || id.length === 0) {
        errorCode = 'E01';
      } else if (password === null || password.length === 0) {
        errorCode = 'E02';
      }
      return {
        Auth: {
          Username: id,
          Password: password
        },
        Error: errorCode
      };
    },
    /**
     * ユーザー認証
     * @access private
     * @param {object} authenticationData 認証入力情報
     */
    authenticateUser_: function (authenticationData) {
      var that = this;
      // セッションデータをすべて削除する
      Common.removeSessionAll();

      var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      var cognitoUser = Common.getCognitoUser(authenticationData['Username']);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          cognitoUser.getSession(function (err, result) {
            if (err) {
              Common.removeLoading();
              that.$MessageParagraph.showMessage(Common.MSG.LOGIN_ERROR_001);
              return;
            }
            if (!result) {
              Common.removeLoading();
              that.$MessageParagraph.showMessage(Common.MSG.LOGIN_ERROR_001);
              return;
            }

            // ユーザー認証成功後の処理
            that.setPartsUserInfoForSession_(authenticationData);
            that.onAuthenticateSuccess_().catch(function () {
              Common.removeSessionAll();
            }).finally(function () {
              Common.removeLoading();
            });
          });
        },
        onFailure: function () {
          // ユーザー認証に失敗
          Common.removeLoading();
          that.$MessageParagraph.showMessage(Common.MSG.LOGIN_ERROR_001);
        }
      });
    },
    /**
     * ユーザー情報（一部）をセッションに保存する
     * @access private
     * @param {object} authenticationData 認証入力情報
     */
    setPartsUserInfoForSession_: function (authenticationData) {
      var userInfo = {
        userId: authenticationData['Username']
      };
      // ユーザー情報をセッションに保存
      Common.setValueForSession(Const.SESSION_KEY.USER_INFO, JSON.stringify(userInfo));
    },
    /**
     * ユーザー認証成功後の処理
     * @access private
     * @returns {Promise} 処理結果
     */
    onAuthenticateSuccess_: function () {
      var that = this;
      // ユーザー取得API呼出
      var pmGetUserInfo = CommonApi.getUserInfo();
      pmGetUserInfo.then(function (data) {
        if (typeof data === 'undefined' || data === null) {
          throw new Error();
        }
        var userInfo = {
          userId: data.user_id,
          userType: data.user_type,
          userName: data.user_name,
          mailAddress: data.mail_address,
          userGroupId: data.user_group_id,
          releaseVersion: data.release_version
        };
        // ユーザー情報をセッションに保存
        Common.setValueForSession(Const.SESSION_KEY.USER_INFO, JSON.stringify(userInfo));
        // ユーザー種別ごとのホーム画面に遷移する
        Common.pageTransitionByUserType();
      });
      pmGetUserInfo.catch(function (data) {
        if (data.message) {
          that.$MessageParagraph.showMessage(data.message);
        }
      });
      return pmGetUserInfo;
    }
  };
}());
